<template>
  <validation-observer
    ref="examinationSchedulerSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="examinationSchedulerSaveModal"
      body-class="position-static"
      centered
      no-close-on-backdrop
      :title="isCreated ? `Thêm ${resourceName.fullName}` : `Cập nhật ${resourceName.fullName}`"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="admissionRoundId">
              <template v-slot:label>
                Đợt tuyển sinh <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Đợt tuyển sinh"
                rules="required"
              >
                <v-select
                  v-model="targetData.admissionRoundId"
                  :options="admissionRounds"
                  :reduce="option => option.value"
                  @input="readAdmissionForm"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="admissionFormId">
              <template v-slot:label>
                Phương thức tuyển sinh <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Phương thức tuyển sinh"
                rules="required"
              >
                <v-select
                  v-model="targetData.admissionFormId"
                  :options="dataListAdmissionForm"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên kỳ thi <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên kỳ thi"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="targetData.name"
                  name="name"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="startDate">
              <template v-slot:label>
                Ngày bắt đầu <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Ngày bắt đầu"
                rules="required"
              >
                <flat-pickr
                  v-model="targetData.startDate"
                  class="form-control"
                  :config="config"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="endDate">
              <template v-slot:label>
                Ngày kết thúc <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Ngày kết thúc"
                rules="required"
              >
                <flat-pickr
                  v-model="targetData.endDate"
                  class="form-control"
                  :config="config"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="targetData.status"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('examinationSchedulerSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal, BOverlay, BFormInvalidFeedback, BButton, BRow, BCol, BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'
import { getUser } from '@/auth/utils'
import flatPickr from 'vue-flatpickr-component'
import FlatpickrLanguages from 'flatpickr/dist/l10n'

export default {
  name: 'ExaminationSchedulerSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    BCol,
    BRow,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    mainData: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetData: {
        id: 0,
        name: '',
        admissionRoundId: 0,
        admissionFormId: 0,
        status: 0,
        startDate: '',
        endDate: '',
      },
      config: {
        enableTime: true,
        altInput: true,
        altFormat: 'd/m/Y H:i',
        dateFormat: 'd/m/Y H:i',
        locale: FlatpickrLanguages.vn,
        time_24hr: true,
      },
      required,
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
      statuses: 'admissionExaminationScheduler/statuses',
      resourceName: 'admissionExaminationScheduler/resourceName',
      admissionRounds: 'admissionRound/admissionRoundsByOrganization',
      dataListAdmissionForm: 'admissionForm/admissionFormByAdmissionRoundId',
    }),
    statusOptions() {
      return this.statuses
    },
    isCreated() {
      return !this.mainData
    },
  },
  methods: {
    ...mapActions({
      createData: 'admissionExaminationScheduler/createData',
      updateData: 'admissionExaminationScheduler/updateData',
      readAdmissionFormByAdmissionRoundId: 'admissionForm/readAdmissionFormByAdmissionRoundId',
      getAdmissionRoundsByOrganization: 'admissionRound/readAdmissionRoundsByOrganization',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onTrainingSystemsChange(event) {
      this.targetData.courseId = null
      await this.getCourses({ organizationId: getUser().orgId, trainingSystemId: event })
    },
    async onCoursesChange(event) {
      this.targetData.courseSemesterId = null
      await this.getCourseSemesters({ organizationId: getUser().orgId, courseId: event })
    },
    async readAdmissionForm(id) {
      await this.readAdmissionFormByAdmissionRoundId({ admissionRoundId: id })
    },
    async onShow() {
      this.isLoading = true
      if (this.mainData) {
        this.targetData = this.mainData
      }
      if (this.isCreated) {
        await Promise.all([
          this.getAdmissionRoundsByOrganization({ organizationId: getUser().orgId })
        ])
        this.targetData.status = 0
      } else {
        await Promise.all([
          this.getAdmissionRoundsByOrganization({ organizationId: getUser().orgId }),
          this.readAdmissionFormByAdmissionRoundId({ admissionRoundId: this.targetData.admissionRoundId})
        ])
      }
      this.isLoading = false
    },
    onHide() {
      this.$refs
        .examinationSchedulerSaveFormRef
        .reset()
      this.targetData = {
        id: 0,
        name: '',
        admissionRoundId: 0,
        admissionFormId: 0,
        status: 0,
        startDate: '',
        endDate: '',
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .examinationSchedulerSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createData(this.targetData)
            : await this.updateData(this.targetData)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('examinationSchedulerSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
